// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-pages-index-page-js": () => import("./../src/components/pages/index-page.js" /* webpackChunkName: "component---src-components-pages-index-page-js" */),
  "component---src-components-pages-tag-page-js": () => import("./../src/components/pages/tag-page.js" /* webpackChunkName: "component---src-components-pages-tag-page-js" */),
  "component---src-components-pages-post-page-js": () => import("./../src/components/pages/post-page.js" /* webpackChunkName: "component---src-components-pages-post-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

